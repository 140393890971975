import React from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { products } from "./products";
import { Link } from "react-router-dom";
import useToken from './useToken';
import Login from './Login'

const get_link = s => {
    var result = ""
    if(s.includes("&"))
        result = s.replaceAll(" ","").replace("&","-");        
    else if(s.includes("+"))
        result = s.replaceAll(" ","").replace("+","-");
    else result = s.replace(" ","-")
    return result.toLocaleLowerCase();
}

export default function Catalog () {
        const { token, setToken } = useToken();
        // if(!token) {
        //   return <Login setToken={setToken} />
        // }
        const tabs = products;
        return (
             <React.Fragment>
                 <div className='py-5'>
                    <div className="row">
                      <div className="col mx-auto text-center text-slanted text-blue">
                        <h1>{"Список брендов по алфавиту"}</h1>
                      </div>
                    </div>
                    <div className='container'>
                        <TabItems  items={tabs}/>    
                    </div>
                 </div>
             </React.Fragment>
        )
    }

const ProductItems = props => { 
    const rows = props.items.map((item, index) => {
        return (
            <Link key={index} to={{pathname:"/filters",state:{index: get_link(item),from: 1}}} >
                <p>{item}</p>
            </Link>
        );
    });

    return <ul>{rows}</ul>
}

const TabItems = props => { 
    const tabs = props.items.map((item, index) => {
        return (
            <Tab key={index} eventKey={item.name} title={item.name}>
                <ProductItems items={item.products}/>
            </Tab>
        );
    });

    return  <Tabs defaultActiveKey="A" id="uncontrolled-tab-example">
                  {tabs}    
            </Tabs>
}
