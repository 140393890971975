import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ButtonContainer } from "./Button";
import { imagePath} from './helper';

function ReturnButton({indexName,from, returnTo }) {

  if(returnTo === 'search'){
    return (
      <Link to={{pathname:"/search",state: {index: indexName,from: from}}}>
          <ButtonContainer>Назад в поиск</ButtonContainer>
      </Link>
    );
  }

  return (
    <Link to={{pathname:"/filters",state: {index: indexName,from: from}}}>
        <ButtonContainer>Назад в каталог</ButtonContainer>
    </Link>
  );
  
}

export default class FilterDetails extends Component {

  state = {
      details: {},
      indexName: "",
      from: 0,
      returnTo: ''
  }

  componentDidMount() {
    if(this.props.location.state)
      this.setState(() => {
        return { details: this.props.location.state.item, 
                indexName: this.props.location.state.index,
                page: this.props.location.state.page,
                returnTo: this.props.location.state.returnTo};
      })
    }

  render() {
    const filter = this.state.details;
    const indexName = this.state.indexName;
    const from = this.state.page;
    const returnTo = this.state.returnTo;
    const spec = [];
    if(Object.keys(filter).length > 0){
      const article = filter.article.split(' ')[0];
      const restArticle = filter.article.substring(filter.article.indexOf(' ')+1);
      spec.push(<div className="row" key={100}>
      <div className="col"><p className="text-muted lead">{article}</p></div>
      <div className="col"><p className="text-muted lead">{restArticle}</p></div>
    </div>);
      for (const [index, value] of filter.spec.entries()) {
        const split = value.split(":");
        spec.push(<div className="row" key={index}>
          <div className="col"><p className="text-muted lead">{split[0]}</p></div>
          <div className="col"><p className="text-muted lead">{split[1]}</p></div>
        </div>);
      }
    }
    return (
      <div className="container py-5">
      {/*title*/}
      <div className="row">
        <div className="col mx-auto text-center text-slanted text-blue text-capitalize">
          <h1>{filter.title}</h1>
        </div>
      </div>
      {/*end title */}
      {/*product*/}
      <div className="row">
        <div className="col-10 mx-auto col-md-6 my-3">
          <div className="detail-shadow">
            <img src={imagePath(filter.image)} className="img-fluid" />
          </div>
        </div>
        <div className="col-10 mx-auto col-md-6 my-3 text-capitalize">
          <div className="detail-shadow-padded">
            {spec}
          </div>
          <div>
            <ReturnButton indexName = {indexName} from = {from} returnTo = {returnTo}/>
          </div>
        </div>
      </div>
      {/*end product*/}
    </div>
    );
  }
}
