import React from 'react'
import styled from "styled-components";
import { Link } from "react-router-dom";
import { popularItems } from "./popular_items";

export default function MainPage () {
  const popular_items = popularItems
  return (
        <React.Fragment>
            <div className='py-5'>
              <div className="row">
                <div className="col mx-auto text-center text-slanted text-blue">
                  <h1>{"Спецснаб онлайн - Фильтры для техники и оборудования"}</h1>
                </div>
              </div>
              <div className='container'>
                  <div className='row'>
                      <PopularItem items={popular_items}/>
                  </div>
              </div>
            </div>
        </React.Fragment>
  )
}


const PopularItem = props => { 
    const items = props.items;
    const rows = items.map((item, index) => {
        const indexName = item.brend;
        return (
            <ProductWrapper key={index} className="col-9 mx-auto col-md-6 col-lg-3 my-3">
              <div className="card">
                <div className="img-container p-5">
                    <Link to={{pathname:"/filters",state:{index: indexName, from: 1}}}>
                        <img  src={item.img} alt="brand" className="card-img-top"></img>
                    </Link>
                </div>
              </div>
            </ProductWrapper>
        );
    });

    return rows;
}

const ProductWrapper = styled.div`
  .card {
    border-color: transparent;
    transition: all 1s linear;
  }
  .card-footer {
    background: transparent;
    border-top: transparent;
    transition: all 1s linear;
  }
  &:hover {
    .card {
      border: 0.04rem solid rgba(0, 0, 0, 0.2);
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    }
    .card-footer {
      background: rgba(247, 247, 247);
    }
  }
  .img-container {
    position: relative;
    overflow: hidden;
  }
  .card-img-top {
    transition: all 0.5s linear;
  }
  .img-container:hover .card-img-top {
    transform: scale(1.2);
  }
`;