import { useState } from 'react';
import jwt_decode from "jwt-decode";

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    if(tokenString){
      const userToken = JSON.parse(tokenString);
      const { exp } = jwt_decode(userToken?.token);
      const expirationTime = (exp * 1000) - 60000;
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        return undefined;
      }
      return userToken?.token
    }
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token
  }
}