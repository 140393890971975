import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'


export default class Contact extends Component {
    render() {
        return (
             <React.Fragment>
                 <div className='py-5'>
                     <div className="row">
                      <div className="col mx-auto text-center text-slanted text-blue">
                        <h1>{"Контакты"}</h1>
                      </div>
                    </div>
                    <div className='container'>
                    <Table striped bordered hover size='sm' className="mt-2">
                        <tr>
                            <td><h4><span className='text-blue'>Телефон</span></h4></td>
                            <td><h4><span><a href='tel:+9228863555'>+9228863555</a></span></h4></td>
                        </tr>
                        <tr>
                            <td><h4><span className='text-blue'>Whatsapp</span></h4></td>
                            <td><h4><span><a href='https://wa.me/79228863555'>+9228863555</a></span></h4></td>
                        </tr>
                        <tr>
                            <td><h4><span className='text-blue'>Telegram</span></h4></td>
                            <td><h4><span><a href="https://t.me/spesnab56">+9228863555</a></span></h4></td>
                        </tr>
                        <tr>
                            <td><h4><span className='text-blue'>Почта</span></h4></td>
                            <td><h4><span><a href='mailto: specsnab56@inbox.ru'>specsnab56@inbox.ru</a></span></h4></td>
                        </tr>
                    </Table>
                    <div className="row">
                      <div className="col mx-auto text-center text-slanted text-blue">
                        <h1>{"Реквизиты"}</h1>
                      </div>
                    </div>
                    <Table striped bordered hover size='sm' className="mt-2">
                        <tr>
                            <td><h4><span className='text-blue'>ООО</span></h4></td>
                            <td><h4><span>Спецснаб 56</span></h4></td>
                        </tr>
                        <tr>
                            <td><h4><span className='text-blue'>ИНН</span></h4></td>
                            <td><h4><span>5610236800</span></h4></td>
                        </tr>
                        <tr>
                            <td><h4><span className='text-blue'>КПП</span></h4></td>
                            <td><h4><span>561901001</span></h4></td>
                        </tr>
                        <tr>
                            <td><h4><span className='text-blue'>ОГРН</span></h4></td>
                            <td><h4><span>1195658015541</span></h4></td>
                        </tr>
                    </Table>
                    </div>
                 </div>
             </React.Fragment>
        )
    }
}