import React, { useState, useEffect} from 'react'
import styled from "styled-components";
import { Link , useHistory} from "react-router-dom";
import { getEndpoint, imagePath} from './helper';
// import Login from './Login';
// import useToken from './useToken';
import Pagination from './Pagination';


const defaultPageSize = 8;
const pageLimit = 30;

export default function Filters(props) {
  const history = useHistory();
  const [index,setIndex] = useState(() =>{
    return props.location.state === undefined ? '' : props.location.state.index;
  });
  const [page,setPage] = useState(() =>{
    return props.location.state === undefined ? 1 : props.location.state.from;
  });
  const [filters,setFilters] = useState([]);
  //const {token, setToken } = useToken();
  const [total,setTotal] = useState(0);

  useEffect(()=> {
      search(); 
    },[page, index])

  const search = async () => {
    if(index === ''){
      history.push('/');
      return;
    }
    const url = getEndpoint(`/search/filters?page=${page}&index=${index}`);
    try{    
        const response = await fetch(url)
        const result = await response.json();
        if(response.status !== 404){
          setFilters(result.result);
          setTotal(result.total);
        }
      }catch(error){
        console.log(error);
      }
    
}

  const onPageChanged = data => {
    if(data.currentPage > 0)
      setPage(data.currentPage);
  }

  // if(!token) {
  //   return <Login setToken={setToken} />
  // }
  return (
          <React.Fragment>
              <div className='py-5'>
              <div className="row">
                <div className="col mx-auto text-center text-slanted text-blue text-capitalize">
                  <h1>{index}</h1>
                </div>
              </div>
                  <div className='container'>
                      <div className='row'>
                          <FilterItem items={filters} indexName={index} page={page}/>
                      </div>
                      <div className="d-flex flex-row py-4 align-items-center">
                        <Pagination totalRecords={total} pageLimit={8} pageNeighbours={2} currentPage={page} onPageChanged={onPageChanged} />
                      </div>
                  </div>
              </div>
          </React.Fragment>
  )
}

const FilterItem = props => { 
    const indexName = props.indexName;
    const page = props.page;
    const rows = props.items.map((item, index) => {
        return (
            <ProductWrapper key={index} className="col-9 mx-auto col-md-6 col-lg-3 my-3">
            <div className="card">
                  <div className="img-container p-5">
                    <Link to={{pathname:"/details",state: {item: item, index: indexName,page: page , returnTo: 'filters'}}}>
                      <img src={imagePath(item.image)} alt="filter" className="card-img-top"></img>
                    </Link>
                  </div>
              {/* card footer */}
              <div className="card-footer d-flex justify-content-between">
                <p className="align-self-center mb-0">{item.title}</p>
              </div>
            </div>
          </ProductWrapper>
        );
    });

    return rows;
}

const ProductWrapper = styled.div`
  .card {
    border-color: transparent;
    transition: all 1s linear;
  }
  .card-footer {
    background: transparent;
    border-top: transparent;
    transition: all 1s linear;
  }
  &:hover {
    .card {
      border: 0.04rem solid rgba(0, 0, 0, 0.2);
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    }
    .card-footer {
      background: rgba(247, 247, 247);
    }
  }
  .img-container {
    position: relative;
    overflow: hidden;
  }
  .card-img-top {
    transition: all 0.5s linear;
  }
  .img-container:hover .card-img-top {
    transform: scale(1.2);
  }
  .cart-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.2rem 0.4rem;
    background: var(--lightBlue);
    border: none;
    color: var(--mainWhite);
    font-size: 1.4rem;
    border-radius: 0.5rem 0 0 0;
    transform: translate(100%, 100%);
    transition: all 0.5s linear;
  }

  .img-container:hover .cart-btn {
    transform: translate(0, 0);
  }
  .cart-btn:hover {
    color: var(--mainBlue);
    cursor: pointer;
  }
  .cart-btn:focus {
    outline: none;
  }
`;