import styled from 'styled-components'


export const ButtonContainer = styled.button`
font-size: 1.1rem;
background: transparent;
border: 0.05rem solid var(--lightBlue);
border-color: var(--lightBlue);
color: var(--lightBlue);
border-radius: 0.5rem;
padding: 0.2rem 0.5rem;
cursor:pointer;
margin: 0.2rem 0.5rem 0.2rem 0;
transition: all 0.5s easy-in-out;
&:hover{
    background: var(--lightBlue);
    color:var(--mainWhite);
}
&:disabled{
    background: grey;
  }
&:focus{
    outline: none;
}
`
