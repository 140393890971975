import React from 'react';
import Table from 'react-bootstrap/Table'

export default function SearchTable({items}) {
    const size = 'sm';
    return (
        <Table striped bordered hover size={size} className="mt-2">
            <TableHeader />
            <TableBody items={items}/>
        </Table>
    );
    
}

const TableHeader = () => { 
    return (
        <thead >
            <tr className="text-capitalize">
                <th>производитель</th>
                <th>артикул</th>
                <th>аналог</th>
                <th>артикул</th>
            </tr>
        </thead>
    );
}

const TableBody = props => { 
    const rows = props.items.map((item, index) => {
        return (
            <tr key={index}>
                <td>{item.from}</td>
                <td>{item["original-part"]}</td>
                <td>{item.to}</td>
                <td>{item["cross-part"]}</td>
            </tr>
        );
    });

    return <tbody>{rows}</tbody>;
}
