import React, { useRef, useState} from 'react'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import SearchTable from './SearchTable'
import { getEndpoint } from './helper';
import Login from './Login';
import useToken from './useToken';

export default function Search(){
  const[filters,setFilters] = useState([]);
  const searchInput = useRef(null);
  const { token, setToken } = useToken();

  const onSearch = e => {
    const query = searchInput.current.value;
    if(query.trim() !== "")
        search(query)
  }

  const search = async(query) => {
    const index = 'industrial-filters';
    const url = getEndpoint(`/search/articles?size=30&q=${query}&index=${index}`);
    try{
      const response = await fetch(url);
      const result = await response.json();
      setFilters(result);
    }catch(error){}
  }
  // if(!token) {
  //   return <Login setToken={setToken} />
  // }
  return (
      <React.Fragment>
          <div className="py-5">
              <div className="row">
                <div className="col mx-auto text-center text-slanted text-blue">
                  <h1>{"Поиск по артикулу фильтра"}</h1>
                </div>
              </div>
              <div className="container-fluid">
              <Form inline onSubmit={e => { e.preventDefault(); }}>
                  <FormControl autoFocus ref={searchInput} onChange={onSearch} type="text" placeholder="введите артикул" className="mx-auto" style={{width: '500px'}} />
              </Form>
              <SearchTable items={filters}/>
          </div>
      </div>
      </React.Fragment>
  )
}
