import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {Switch,Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Cart from './components/Cart';
import Default from './components/Default';
//import Modal from './components/Modal';
import Search from './components/Search';
import CrossSearch from './components/CrossSearch';
import MainPage from './components/MainPage'
import Contact from './components/Contact'
import Catalog from './components/Catalog'
import Filters from './components/Filters'
import FilterDetails from './components/FilterDetails'

function App() {
  return (
    <React.Fragment>          
      <Navbar />
      <Switch>
        <Route exact path='/' component={MainPage} />
        <Route path='/catalog' component={Catalog}/>
        <Route path='/contact' component={Contact}/>
        <Route path='/details' component={FilterDetails} />
        <Route path='/cart' component={Cart} />
        <Route path='/filters' component={Filters} />
        <Route path='/search' component={Search}/>
        <Route path='/crosssearch' component={CrossSearch}/>
        <Route component={Default} />
      </Switch>
      {/* <Modal/> */}
    </React.Fragment>
  );
}


export default App;