import React, { useRef, useState} from 'react'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import SearchTable from './SearchTable'
import { getEndpoint } from './helper';
import Login from './Login';
import useToken from './useToken';
import Table from 'react-bootstrap/Table'
import { Link } from "react-router-dom";

function CatalogSearchTable({items}) {
  const size = 'sm';
  return (
      <Table striped bordered hover size={size} className="mt-2">
          <CatalogTableHeader />
          <CatalogTableBody items={items}/>
      </Table>
  );
  
}

const CatalogTableHeader = () => { 
  return (
      <thead >
          <tr className="text-capitalize">
              <th>наименование</th>
              <th>артикул</th>
          </tr>
      </thead>
  );
}

const CatalogTableBody = props => { 
  const rows = props.items.map((item, index) => {
    const indexName = item.spec[0].split(':')[1].trim().toLowerCase();
      return (
          <tr key={index}>
              <td> <Link to={{pathname:"/details",state: {item: item, index: indexName,from: 0, returnTo: 'search'}}}>
                        {item.title}
                    </Link></td>
              <td>{item.article.replace('Артикул ', '')}</td>
          </tr>
      );
  });

  return <tbody>{rows}</tbody>;
}

export default function Search(){
  const[filters,setFilters] = useState([]);
  const searchInput = useRef(null);
  const { token, setToken } = useToken();

  const onSearch = e => {
    const query = searchInput.current.value;
    if(query.trim() !== "")
        search(query)
  }

  const search = async(query) => {
    const index = 'industrial-filters';
    const url = getEndpoint(`/search/catalog?size=30&q=${query}&index=${index}`);
    try{
      const response = await fetch(url);
      const result = await response.json();
      setFilters(result);
    }catch(error){}
  }
  // if(!token) {
  //   return <Login setToken={setToken} />
  // }
  return (
      <React.Fragment>
          <div className="py-5">
              <div className="row">
                <div className="col mx-auto text-center text-slanted text-blue">
                  <h1>{"Поиск по каталогу"}</h1>
                </div>
              </div>
              <div className="container-fluid">
              <Form inline onSubmit={e => { e.preventDefault(); }}>
                  <FormControl autoFocus ref={searchInput} onChange={onSearch} type="text" placeholder="начните печатать запрос для поиска" className="mx-auto" style={{width: '500px'}} />
              </Form>
              <CatalogSearchTable items={filters}/>
          </div>
      </div>
      </React.Fragment>
  )
}
