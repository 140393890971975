export const popularItems = [
    {
        brend: 'sf-filter',
        img:'img/sf_filter.jpeg'
    },
    {
        brend: 'baldwin',
        img:'img/baldwin.jpeg'
    },
    {
        brend: 'donaldson',
        img:'img/donaldson.jpeg'
    },
    {
        brend: 'fleetguard',
        img:'img/fleetguard.jpeg'
    },
    {
        brend: 'hifi',
        img:'img/hifi.jpeg'
    },
    {
        brend: 'hydac',
        img:'img/hydac.jpeg'
    },
    {
        brend: 'mahle',
        img:'img/mahle.jpeg'
    },
    {
        brend: 'mann',
        img:'img/mann.jpeg'
    },
    {
        brend: 'parker',
        img:'img/parker.jpeg'
    },
    {
        brend: 'wix',
        img:'img/wix.jpeg'
    },
    {
        brend: 'fai',
        img:'img/fai.png'
    },
    {
        brend: 'fil',
        img:'img/fil.png'
    }
]