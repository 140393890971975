export const products = [
    {'name': 'A', 'products': ['ABAC', 'AC', 'AEBI', 'AGCO', 'AHLMANN', 'AIR FILTER', 'ALCO', 'ALFA ROMEO', 'ALUP', 'AMC', 'AMMANN', 'ARGO', 'ATLAS', 'ATLAS COPCO'
        ]
    },
    {'name': 'B', 'products': ['BALDWIN', 'BALSTON', 'BAUER', 'BEA FILTRI', 'BECKER', 'BEKO', 'BELL', 'BESTAIR', 'BMW', 'BOBCAT', 'BOGE', 'BOLL & KIRCH', 'BOMAG', 'BOSCH', 'BOTTARINI', 'BRIGGS-STRATTON', 'BUCHER-GUYER', 'BUSCH', 'BUSSE & KUNTZE'
        ]
    },
    {'name': 'C', 'products': ['CAP', 'CARQUEST', 'CASE', 'CATERPILLAR', 'CAV', 'CECCATO', 'CHAMPION', 'CIMTEK', 'CITROEN', 'CLAAS', 'CLARK', 'CLEAN', 'COMEX', 'COMPAIR-HOLMAN', 'COOPERS', 'CORTECO', 'CROSLAND', 'CUMMINS'
        ]
    },
    {'name': 'D', 'products': ['DAEWOO VL', 'DAF', 'DAIHATSU', 'DELTECH', 'DEMAG', 'DIGOEMA', 'DOMNICK HUNTER', 'DONALDSON', 'DYNAPAC'
        ]
    },
    {'name': 'E', 'products': ['ECOAIR', 'ELSAESSER', 'EPE', 'EUCLID', 'EUROGARDEN', 'EUROPART'
        ]
    },
    {'name': 'F', 'products': ['F+T', 'FAI', 'FAIREY', 'FARR', 'FAUN', 'FBN', 'FBO', 'FENDT', 'FIAAM', 'FIAT', 'FILTRON', 'FINN FILTER', 'FLEETGUARD', 'FORD', 'FRAM', 'FUCHS'
        ]
    },
    {'name': 'G', 'products': ['GARDNER DENVER', 'GEHL', 'GM', 'GROVE', 'GUTBROD'
        ]
    },
    {'name': 'H', 'products': ['HAKO', 'HAMM', 'HANKISON', 'HARMSCO', 'HAYWARD', 'HATZ', 'HENGST', 'HIFI', 'HINO', 'HIROSS', 'HITACHI', 'HOKUETSU', 'HONDA', 'HUSQVARNA', 'HYDAC', 'HYDROVANE', 'HYPRO', 'HYSTER', 'HYUNDAI'
        ]
    },
    {'name': 'I', 'products': ['IKRON', 'INGERSOLL', 'INTERNORMEN', 'ISEKI', 'ISUZU'
        ]
    },
    {'name': 'J', 'products': ['JCB', 'JLG', 'JOHN DEERE', 'JUNGHEINRICH'
        ]
    },
    {'name': 'K', 'products': ['KAELBLE', 'KAESER', 'KAESSBOHRER', 'KALMAR', 'KAWASAKI', 'KHD', 'KIA', 'KNORR', 'KOBELCO', 'KOHLER', 'KOLBENSCHMIDT', 'KOMATSU', 'KRAMER', 'KUBOTA'
        ]
    },
    {'name': 'L', 'products': ['LAUTRETTE', 'LEYBOLD', 'LHA', 'LIEBHERR', 'LINDE', 'LOMBARDINI', 'LUBERFINER'
        ]
    },
    {'name': 'M', 'products': ['MACK', 'MACO-MEUDON', 'MAHLE', 'MAN', 'MANITOU', 'MANN', 'MARK', 'MASSEY FERGUSON', 'MASUDA', 'MATTEI', 'MAZDA', 'MC CORMICK', 'MECALAC', 'MENZI-MUCK', 'MERCEDES', 'MERLO', 'MICRON AIR', 'MIOFILTRE', 'MITSUBISHI', 'MODINA', 'MOPAR', 'MOPAR', 'MOTORCRAFT', 'MP FILTRI', 'MTU', 'MWM'
        ]
    },
    {'name': 'N', 'products': ['NELSON', 'NEUSON', 'NEW HOLLAND', 'NIPPARTS', 'NIPPON DENSO', 'NISSAN'
        ]
    },
    {'name': 'O', 'products': ['O & K', 'OMT', 'ONAN', 'OPEL', 'OREGON'
        ]
    },
    {'name': 'P', 'products': ['PALL', 'PANCLEAN', 'PARKER', 'PEL JOB', 'PERMATIC', 'PEUGEOT', 'PLASSER', 'PRINOTH', 'PRO FILTERS', 'PURFLUX', 'PUROLATOR'
        ]
    },
    {'name': 'R', 'products': ['RACOR', 'REFORM MULI', 'REGELTECHNIK', 'RENAULT', 'REXROTH', 'RIETSCHLE', 'ROBIN', 'ROTAIR'
        ]
    },
    {'name': 'S', 'products': ['SLH', 'SAMSUNG', 'SANDVIK', 'SAVARA', 'SCANIA', 'SCHAEFF', 'SCHAEFER', 'SCT', 'SDF', 'SDMO', 'SENNEBOGEN', 'SEPAR', 'SF-FILTER', 'SMC', 'SOFIMA', 'SOTRAS', 'ST', 'STANADYNE', 'STAUFF', 'STENS', 'STEYR', 'STIHL', 'STILL', 'SUBARU', 'SULLAIR', 'SURE FILTER', 'SUZUKI'
        ]
    },
    {'name': 'T', 'products': ['TAISEI KOGYO', 'TAKEUCHI', 'TAMROCK', 'TCM', 'TECNOCAR', 'TECUMSEH', 'TEREX', 'THERMO-KING', 'THWAITES', 'TIMBER-JACK', 'TORIT', 'TORO', 'TOYOTA', 'TROCAIR'
        ]
    },
    {'name': 'U', 'products': ['UCC', 'UFI', 'UNIPART'
        ]
    },
    {'name': 'V', 'products': ['VAG', 'VALEO', 'VERMEER', 'VICKERS', 'VIRGIS', 'VOLVO'
        ]
    },
    {'name': 'W', 'products': ['WACKER', 'WALKER', 'WEBASTO', 'WEIDEMANN', 'WINKLER', 'WIRTGEN', 'WISMET', 'WIX', 'WOODGATE', 'WORTHINGTON'
        ]
    },
    {'name': 'Y', 'products': ['YALE', 'YAMAHA', 'YAMASHIN', 'YANMAR'
        ]
    },
    {'name': 'Z', 'products': ['ZANDER', 'ZETTELMEYER', 'ZF', 'ZINGA'
        ]
    }
]