import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../specLogo.svg'
import styled from 'styled-components'
import {ButtonContainer} from './Button';
import useToken from './useToken';

export default function Navbar(){
    const { token, setToken } = useToken();
    const onLogout = e => {
        setToken(null);
      }
        return (
            <NavWrapper className='navbar navbar-expand-sm navbar-dark px-sm-5'>
            {/* 
            https://www.iconfinder.com/icons/1243689/call_phone_icon
            Creative Commons (Attribution 3.0 Unported);
            https://www.iconfinder.com/Makoto_msk */}
            <Link to='/'>
                <img  src={logo} alt='specsnab' height={64} className='navbar-brand'/>
            </Link>
            <h2 className='main-title'>спецснаб</h2>
            <ul className='navbar-nav align-items-center'>
                <li className='nav-item ml-5'></li>
                <Link to='/'  className='nav-link'>ГЛАВНАЯ</Link>
                <Link to='/catalog'  className='nav-link'>КАТАЛОГ</Link>
                <Link to='/search'  className='nav-link'>ПОИСК</Link>
                <Link to='/crosssearch'  className='nav-link'>КРОСС ПОИСК</Link>
                <Link to='/contact'  className='nav-link'>КОНТАКТЫ</Link>
            </ul>
            {/* <Link to='/cart' className='ml-auto'>
                <ButtonContainer>
                    <span className='mr-2'>
                        <i className='fa fa-user-plus'/>
                    </span>
                    регистрация
                </ButtonContainer>
            </Link> */}
            </NavWrapper>
        )
}
const NavWrapper = styled.nav`
background: var(--mainBlue);
.nav-link:var(--mainWhite)!important;
font-size:1.3rem;
text-transform: capitalize;
`
